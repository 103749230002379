import clsx from 'clsx'
import { useEffect } from 'react'
import { useRouter } from 'next/router'
import { Container, makeStyles } from '@material-ui/core'

import Header from './Header/Container'
import Footer from './Footer'
import theme from '../theme'

const useStyles = makeStyles({
  root: {
    height: '100%',
    '& .MuiContainer-root': {
      height: '100%',
    },
  },
  rootBg: {
    background: `radial-gradient(100% 100% at 96.56% 100%, ${theme.palette.primary.light} 0%, ${theme.palette.primary.dark} 100%)`,
  },

  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  wrapperScroll: {
    overflow: 'auto',
    height: '100%',
  },
  main: {
    flex: 1,
    position: 'relative',
  },
  contentPage: {
    padding: '120px 190px',
    [theme.breakpoints.down('lg')]: {
      padding: '80px 0 120px',
    },
    [theme.breakpoints.down('md')]: {
      padding: '60px 0 120px',
    },
    [theme.breakpoints.down('sm')]: {
      padding: '30px 0 90px',
    },
  },
  headerContainer: {
    '&.MuiContainer-root': {
      height: 'auto',
    },
  },
  containerNoPadding: {
    '&.MuiContainer-root': {
      paddingRight: 0,
      paddingLeft: 0,
      maxWidth: '100%',
    },
  },
})

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const Layout = ({ children, footerContent }) => {
  const { pathname, query } = useRouter()
  const { team_id } = query
  const cls = useStyles()
  const teamPage = pathname === '/team'
  const teamOnePage = !!team_id
  const mainPage = pathname === '/'
  const contentPage = !teamPage || !mainPage

  if (teamPage) {
    return (
      <div className={clsx(cls.root, cls.rootBg)}>
        <div className={cls.wrapper}>
          <Container className={cls.headerContainer}>
            <Header />
          </Container>
          <Container className={clsx(cls.main, cls.containerNoPadding)}>
            {children}
          </Container>
        </div>
      </div>
    )
  }

  if (mainPage) {
    return (
      // <div className={cls.wrapperScroll}>
      <div className={clsx(cls.root)}>
        <div className={cls.wrapper} style={{ position: 'relative' }}>
          <div>
            <Container>
              <Header />
            </Container>
          </div>
          <div className={cls.main}>
            {children}
          </div>
        </div>
      </div>
      // </div>
    )
  }

  if (teamOnePage) {
    return (
      <div className={clsx(cls.root, cls.rootBg)}>
        <div className={clsx(cls.wrapper, cls.wrapperScroll)}>
          <Container className={cls.headerContainer}>
            <Header />
          </Container>
          <div className={cls.main}>
            {children}
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className={cls.wrapperScroll}>
      <Container className={cls.root}>
        <div className={cls.wrapper}>
          <Header />
          <div className={clsx(cls.main, contentPage && cls.contentPage)}>
            {children}
          </div>
          <Footer content={footerContent} />
        </div>
      </Container>
    </div>
  )
}

export default Layout
