import ClickAwayListener from '@material-ui/core/ClickAwayListener'

import Nav from '../Nav'


import useStyles from './styles'

const HeaderModal = ({ onClick }) => {
  const cls = useStyles()

  return (
    <div className={cls.modal}>
      <ClickAwayListener onClickAway={onClick}>
        <Nav showNav={true} />
      </ClickAwayListener>
    </div>
  )
}

export default HeaderModal
