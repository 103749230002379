import { makeStyles } from '@material-ui/core'
import theme from '../../theme'

export const useStyles = makeStyles({
  root: {
    borderTop: '1px solid #e0e0e0',
    padding: '40px 0',
    [theme.breakpoints.down('sm')]: {
      padding: '24px 0',
    },
    [theme.breakpoints.down('xs')]: {
      padding: '16px 0',
    },
  },
  address: {
    padding: '24px 0',
    [theme.breakpoints.down('md')]: {
      padding: '16px 0',
    },
    [theme.breakpoints.down('xs')]: {
      padding: '8px 0',
    },
  },
})

export default useStyles
