import { makeStyles } from '@material-ui/core'
import theme from '../../theme'

export const useStyles = makeStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  header: {

  },
  logo: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  logoControl: {
    '& > svg': {
      width: '100%',
      height: '100%',
    },
    [theme.breakpoints.down('md')]: {
      width: 180,
    },
    [theme.breakpoints.down('xs')]: {
      width: 140,
      height: 75,
    },
  },
  isOpen: {
    height: '100%',
    zIndex: 101,
    borderBottom: 'none',
  },
  modal: {
    top: '75px !important',
    position: 'fixed',
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 101,
    background: 'transparent',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  logoIsHidden: {
    visibility: 'hidden',
  },
})

export default useStyles
