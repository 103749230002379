import { makeStyles } from '@material-ui/core'
import theme from '../../theme'

export const useStyles = makeStyles({
  root: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      display: 'none',
      margin: '0',
      width: '100%',
      flexDirection: 'column',
      padding: '1.2143rem 0 1rem',
      alignItems: 'flex-start',
      msOverflowStyle: 'none',
      scrollbarWidth: 'none',
      overflowX: 'auto',
      '&::-webkit-scrollbar': {
        display: 'none',
      },
      '& > a': {
        marginRight: 0,
        marginTop: '1.5rem',
      },
    },
  },
  showNav: {
    display: 'flex',
    justifyContent: 'flex-start',
    height: '100%',
    background: 'white',
    padding: '0 20px',
  },
},
{ name: 'ds-nav' }, // ?
)

export default useStyles
