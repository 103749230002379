
// @ts-ignore
    import __i18nConfig from '@next-translate-root/i18n'
// @ts-ignore
    import __appWithI18n from 'next-translate/appWithI18n'
// @ts-ignore
    
import { CssBaseline, ThemeProvider } from '@material-ui/core'
import { AppPropsType } from 'next/dist/next-server/lib/utils'
import Head from 'next/head'
import dynamic from 'next/dynamic'
import { useRouter } from 'next/router'
import { useEffect } from 'react'

import '../assets/global.css'

import Layout from '../components/Layout'
import theme from '../theme'

const GDPR = dynamic(() => import('../components/GDPR'), { ssr: false })

const CustomApp = ({
  Component,
  pageProps,
}: AppPropsType) => {

  const { pathname } = useRouter()

  useEffect(() => {
    const jssStyles = document.querySelector('#jss-server-side')
    if (jssStyles) {
      jssStyles.parentElement.removeChild(jssStyles)
    }
  }, [])

  // Хак для подмены лэйаута в админке
  if (pathname === '/admin') {
    return (
      <Component {...pageProps} />
    )
  }

  // console.log(pageProps)
  return (
    <ThemeProvider theme={theme}>
      <GDPR />
      <CssBaseline />
      <Head>
        <meta name='viewport' content='minimum-scale=1, maximum-scale=1, initial-scale=1, width=device-width' />
        <link rel='shortcut icon' href={'/favicon/favicon.ico'} type='image/x-icon' />
      </Head>
      <Layout footerContent={pageProps.footer}>
        <Component {...pageProps} />
      </Layout>
    </ThemeProvider>
  )
}


// Only uncomment this method if you have blocking data requirements for
// every single page in your application. This disables the ability to
// perform automatic static optimization, causing every page in your app to
// be server-side rendered.
//
// CustomApp.getInitialProps = async (appContext) => {
//   // calls page's `getInitialProps` and fills `appProps.pageProps`
//   const appProps = await App.getInitialProps(appContext);
//
//   return { ...appProps }
// }

const __Page_Next_Translate__ = CustomApp

// @ts-ignore
    export default __appWithI18n(__Page_Next_Translate__, {
// @ts-ignore
      ...__i18nConfig,
// @ts-ignore
      isLoader: true,
// @ts-ignore
      skipInitialProps: false,
// @ts-ignore
      
// @ts-ignore
    });
// @ts-ignore
  