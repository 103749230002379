import clsx from 'clsx'

import IconBurgerAnimated from '../../assets/icons/IconBurgerAnimated'
import Logo from '../Logo'
import useStyles from './styles'

import { HeaderLogoProps } from './types'

const HeaderLogo = (props: HeaderLogoProps) => {
  const cls = useStyles(0)
  const { onNavOpen, onNavClose, isOpen, isMobile } = props

  return (
    <div className={cls.logo}>
      <div className={clsx(cls.logoControl, isOpen && cls.logoIsHidden)} onClick={onNavClose}>
        <Logo />
      </div>
      {isMobile && <IconBurgerAnimated isOpen={isOpen} onClick={onNavOpen} />}
    </div>
  )
}

export default HeaderLogo
