import { Typography } from '@material-ui/core'
import useStyles from './styles'


const Footer = ({ content = null }) => {
  console.log(content)
  const cls = useStyles(0)
  const {
    address = 'qualitativ Steuerberatungsgesellschaft mbH Konradstraße 12  22658 Hamburg',
    tel = '040 001001101',
    fax = '040 001001101',
    email = 'info@qualitativ.de',
  } = content || {}

  return (
    <div className={cls.root}>
      <Typography className={cls.address} variant='body1'>{address}</Typography>
      <Typography variant='body1'>Tel. :{tel}</Typography>
      <Typography variant='body1'>Fax: {fax}</Typography>
      <Typography variant='body1'>{email}</Typography>
    </div>
  )
}

export const getStaticProps = async ({ locale }) => {
  const content = await import('@/content/pages/footer.json').then(x => x.default) as any

  return {
    props: {
      meta: content?.meta?.[locale] || {},
      content: content[locale] || {}, //TODO locale
    },
  }
}

export default Footer
