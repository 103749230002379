import { useState, useEffect, useRef } from 'react'
import useMediaQuery from '@material-ui/core/useMediaQuery'

import theme from '../../theme'
import HeaderInner from './index'
import HeaderModal from './HeaderModal'

const Header = () => {
  const [showNav, setShowNav] = useState(false)
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'))

  // to think about more elegent solution
  useEffect(() => {
    if (!isMobile && showNav) {onNavClose()}
  })

  const onNavOpen = () => {
    if (showNav) {
      onNavClose()
      return
    }

    document.body.style.overflow = 'hidden'
    setShowNav(true)
  }

  const onNavClose = () => {
    document.body.style.overflow = 'visible'
    setShowNav(false)
  }


  return (
    <>
      <HeaderInner
        onNavOpen={onNavOpen}
        onNavClose={onNavClose}
        isOpen={showNav}
        isMobile={isMobile}
      />
      {
        showNav && (
          <HeaderModal
            onClick={onNavClose}
          />
        )
      }
    </>
  )
}

export default Header
