import Nav from '../Nav'
import HeaderLogo from './HeaderLogo'

import useStyles from './styles'

const HeaderInner = (props) => {
  const cls = useStyles(0)
  const { onNavOpen, onNavClose, isOpen, isMobile } = props

  return (
    <div className={cls.root} id={'header_container'}>
      <HeaderLogo
        onNavOpen={onNavOpen}
        onNavClose={onNavClose}
        isOpen={isOpen}
        isMobile={isMobile}
      />
      <Nav />
    </div>
  )
}

export default HeaderInner
