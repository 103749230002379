import cx from 'clsx'
import useStyles from './styles'

const Link = ({
  title = '',
  href = '',
  className = '',
  target = '',
  nav = false,
}) => {
  const cls = useStyles(0)

  return (
    <a className={`${cx({
      [cls.root]: true,
      [cls.nav]: !!nav,
    })} ${className}`} href={href} target={target}>{title}</a>
  )
}

export default Link
