import clsx from 'clsx'
import Link from '../Link'
import { useRouter } from 'next/router'
import useStyles from './styles'

const Nav = ({ showNav }: {showNav?: boolean}) => {
  const { pathname } = useRouter()
  const cls = useStyles(0)

  return (
    <div className={clsx(cls.root, showNav && cls.showNav)}>
      <Link className={pathname === '/team' && 'active'} title={'Kanzlei'} href={'/team'} nav/>
      <Link className={pathname === '/services' && 'active'} title={'Leistungen'} href={'/specializations'} nav/>
      <Link className={pathname === '/projects' && 'active'} title={'Service'} href={'/support'} nav />
      <Link className={pathname === '/contacts' && 'active'} title={'Aktuelles'} href={'/news'} nav/>
    </div>
  )
}

export default Nav
