
import Link from 'next/link'

const IconLogo = ({
  width = 220,
  height = 103,
  style = { cursor: 'pointer' },
}) => {

  return (
    <Link href='/'>
      <svg style={{ ...style }} width={width} height={height} viewBox={`0 0 ${width} ${height}`} fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path d='M123.638 61.5419C90.642 65.9626 49.906 46.8891 33.8457 39.5627C48.446 57.1461 86.3993 95.2598 130.208 90.1149C172.008 85.206 207.589 29.3057 220 0C201.749 20.514 161.114 56.5208 123.638 61.5419Z' fill='#FFC700'/>
        <path d='M5.1543 86.2539C3.54622 86.2539 2.28516 85.5938 1.37109 84.2734C0.457031 82.9531 0 81.1377 0 78.8271C0 76.4912 0.465495 74.6631 1.39648 73.3428C2.33594 72.0225 3.61393 71.3623 5.23047 71.3623C6.96549 71.3623 8.27311 72.0394 9.15332 73.3936H9.25488L9.62305 71.6162H13.9141V92.2461H8.9502V86.292C8.9502 86.0042 9.00098 85.3018 9.10254 84.1846H8.9502C8.1377 85.5641 6.8724 86.2539 5.1543 86.2539ZM7.17285 82.4072C7.92611 82.4072 8.472 82.1745 8.81055 81.709C9.15755 81.2435 9.33105 80.4564 9.33105 79.3477V78.751C9.33105 77.4814 9.15755 76.5758 8.81055 76.0342C8.46354 75.484 7.89225 75.209 7.09668 75.209C5.72559 75.209 5.04004 76.4404 5.04004 78.9033C5.04004 80.1559 5.21354 81.0531 5.56055 81.5947C5.90755 82.1364 6.44499 82.4072 7.17285 82.4072Z' fill='black'/>
        <path d='M27.5996 86L26.9775 84.21H26.6855C26.2708 84.8701 25.6911 85.3779 24.9463 85.7334C24.21 86.0804 23.3424 86.2539 22.3438 86.2539C20.7611 86.2539 19.5296 85.7969 18.6494 84.8828C17.7692 83.9603 17.3291 82.6611 17.3291 80.9854V71.6162H22.293V79.4746C22.293 80.4141 22.4242 81.125 22.6865 81.6074C22.9574 82.0898 23.3975 82.3311 24.0068 82.3311C24.8617 82.3311 25.471 81.9967 25.835 81.3281C26.1989 80.651 26.3809 79.5127 26.3809 77.9131V71.6162H31.3447V86H27.5996Z' fill='black'/>
        <path d='M44.1543 86L43.2021 84.0957H43.1006C42.432 84.9251 41.7507 85.4922 41.0566 85.7969C40.3626 86.1016 39.4655 86.2539 38.3652 86.2539C37.0111 86.2539 35.9447 85.8477 35.166 85.0352C34.3874 84.2227 33.998 83.0801 33.998 81.6074C33.998 80.0755 34.5312 78.9372 35.5977 78.1924C36.6641 77.4391 38.2087 77.016 40.2314 76.9229L42.6309 76.8467V76.6436C42.6309 75.4587 42.0469 74.8662 40.8789 74.8662C39.8294 74.8662 38.4964 75.2217 36.8799 75.9326L35.4453 72.6572C37.1211 71.7939 39.237 71.3623 41.793 71.3623C43.638 71.3623 45.0641 71.8193 46.0713 72.7334C47.0869 73.6475 47.5947 74.9255 47.5947 76.5674V86H44.1543ZM40.4727 82.75C41.0736 82.75 41.5856 82.5596 42.0088 82.1787C42.4404 81.7979 42.6562 81.3027 42.6562 80.6934V79.5762L41.5137 79.627C39.8802 79.6862 39.0635 80.2871 39.0635 81.4297C39.0635 82.3099 39.5332 82.75 40.4727 82.75Z' fill='black'/>
        <path d='M55.9355 86H50.9717V66.2461H55.9355V86Z' fill='black'/>
        <path d='M64.3652 86H59.4014V71.6162H64.3652V86ZM59.2744 68.0361C59.2744 67.2913 59.4818 66.737 59.8965 66.373C60.3112 66.0091 60.9798 65.8271 61.9023 65.8271C62.8249 65.8271 63.4977 66.0133 63.9209 66.3857C64.3441 66.7581 64.5557 67.3083 64.5557 68.0361C64.5557 69.4919 63.6712 70.2197 61.9023 70.2197C60.1504 70.2197 59.2744 69.4919 59.2744 68.0361Z' fill='black'/>
        <path d='M74.877 82.3311C75.5456 82.3311 76.3454 82.166 77.2764 81.8359V85.4541C76.6077 85.7419 75.9688 85.945 75.3594 86.0635C74.7585 86.1904 74.0518 86.2539 73.2393 86.2539C71.5719 86.2539 70.3701 85.8477 69.6338 85.0352C68.8975 84.2142 68.5293 82.9574 68.5293 81.2646V75.3359H66.79V73.3174L68.999 71.7559L70.2812 68.7344H73.5312V71.6162H77.0605V75.3359H73.5312V80.9346C73.5312 81.8656 73.9798 82.3311 74.877 82.3311Z' fill='black'/>
        <path d='M89.1719 86L88.2197 84.0957H88.1182C87.4495 84.9251 86.7682 85.4922 86.0742 85.7969C85.3802 86.1016 84.4831 86.2539 83.3828 86.2539C82.0286 86.2539 80.9622 85.8477 80.1836 85.0352C79.4049 84.2227 79.0156 83.0801 79.0156 81.6074C79.0156 80.0755 79.5488 78.9372 80.6152 78.1924C81.6816 77.4391 83.2262 77.016 85.249 76.9229L87.6484 76.8467V76.6436C87.6484 75.4587 87.0645 74.8662 85.8965 74.8662C84.847 74.8662 83.514 75.2217 81.8975 75.9326L80.4629 72.6572C82.1387 71.7939 84.2546 71.3623 86.8105 71.3623C88.6556 71.3623 90.0817 71.8193 91.0889 72.7334C92.1045 73.6475 92.6123 74.9255 92.6123 76.5674V86H89.1719ZM85.4902 82.75C86.0911 82.75 86.6032 82.5596 87.0264 82.1787C87.458 81.7979 87.6738 81.3027 87.6738 80.6934V79.5762L86.5312 79.627C84.8978 79.6862 84.0811 80.2871 84.0811 81.4297C84.0811 82.3099 84.5508 82.75 85.4902 82.75Z' fill='black'/>
        <path d='M103.035 82.3311C103.704 82.3311 104.504 82.166 105.435 81.8359V85.4541C104.766 85.7419 104.127 85.945 103.518 86.0635C102.917 86.1904 102.21 86.2539 101.397 86.2539C99.7301 86.2539 98.5283 85.8477 97.792 85.0352C97.0557 84.2142 96.6875 82.9574 96.6875 81.2646V75.3359H94.9482V73.3174L97.1572 71.7559L98.4395 68.7344H101.689V71.6162H105.219V75.3359H101.689V80.9346C101.689 81.8656 102.138 82.3311 103.035 82.3311Z' fill='black'/>
        <path d='M112.938 86H107.974V71.6162H112.938V86ZM107.847 68.0361C107.847 67.2913 108.054 66.737 108.469 66.373C108.883 66.0091 109.552 65.8271 110.475 65.8271C111.397 65.8271 112.07 66.0133 112.493 66.3857C112.916 66.7581 113.128 67.3083 113.128 68.0361C113.128 69.4919 112.243 70.2197 110.475 70.2197C108.723 70.2197 107.847 69.4919 107.847 68.0361Z' fill='black'/>
        <path d='M120.174 86L114.689 71.6162H119.869L122.307 80.1221C122.315 80.1644 122.332 80.2363 122.357 80.3379C122.383 80.4395 122.408 80.5622 122.434 80.7061C122.459 80.8415 122.48 80.9896 122.497 81.1504C122.522 81.3027 122.535 81.4466 122.535 81.582H122.624C122.624 81.1419 122.7 80.6637 122.853 80.1475L125.404 71.6162H130.571L125.087 86H120.174Z' fill='black'/>
        <path d='M3.46472 98.9485C3.46472 99.4475 3.30129 99.8275 2.97443 100.089C2.65029 100.35 2.16409 100.48 1.51581 100.48C1.18351 100.48 0.900228 100.456 0.665978 100.406C0.431728 100.36 0.212459 100.29 0.00817151 100.197V99.1008C0.239698 99.2169 0.499824 99.314 0.78855 99.3924C1.08 99.4707 1.33604 99.5098 1.55667 99.5098C2.00883 99.5098 2.23491 99.3706 2.23491 99.0921C2.23491 98.9877 2.20495 98.9036 2.14502 98.8398C2.0851 98.773 1.98159 98.6991 1.8345 98.6178C1.68742 98.5337 1.4913 98.4365 1.24615 98.3263C0.89478 98.1697 0.636016 98.0246 0.469862 97.8912C0.306432 97.7577 0.186583 97.6054 0.110315 97.4343C0.0367718 97.2602 0 97.047 0 96.7947C0 96.3624 0.156621 96.0288 0.469862 95.7939C0.785827 95.556 1.23254 95.4371 1.80999 95.4371C2.3602 95.4371 2.89544 95.5647 3.41569 95.82L3.0398 96.7772C2.811 96.6728 2.59718 96.5872 2.39834 96.5205C2.1995 96.4538 1.99657 96.4204 1.78956 96.4204C1.42184 96.4204 1.23798 96.5263 1.23798 96.7381C1.23798 96.857 1.29655 96.96 1.41367 97.047C1.53352 97.1341 1.79365 97.2631 2.19405 97.4343C2.55087 97.588 2.81236 97.7316 2.97851 97.8651C3.14467 97.9985 3.26724 98.1522 3.34623 98.3263C3.42522 98.5004 3.46472 98.7078 3.46472 98.9485Z' fill='black'/>
        <path d='M6.36152 99.4228C6.57943 99.4228 6.84091 99.3721 7.14598 99.2705V100.258C6.83547 100.406 6.45413 100.48 6.00197 100.48C5.50351 100.48 5.13988 100.347 4.91108 100.08C4.685 99.8101 4.57196 99.4069 4.57196 98.8702V96.5249H3.97544V95.9636L4.66184 95.5197L5.02139 94.4928H5.81811V95.5284H7.09695V96.5249H5.81811V98.8702C5.81811 99.0588 5.86714 99.198 5.9652 99.2879C6.06598 99.3779 6.19809 99.4228 6.36152 99.4228Z' fill='black'/>
        <path d='M9.96515 96.3813C9.70094 96.3813 9.49393 96.4712 9.34412 96.6511C9.19431 96.828 9.10851 97.0804 9.08672 97.4082H10.8354C10.83 97.0804 10.7496 96.828 10.5944 96.6511C10.4391 96.4712 10.2294 96.3813 9.96515 96.3813ZM10.1408 100.48C9.4054 100.48 8.83068 100.264 8.41665 99.8318C8.00263 99.3996 7.79562 98.7875 7.79562 97.9956C7.79562 97.1805 7.98629 96.551 8.36762 96.1071C8.75168 95.6604 9.28147 95.4371 9.95698 95.4371C10.6025 95.4371 11.1051 95.6329 11.4646 96.0245C11.8242 96.4161 12.0039 96.9571 12.0039 97.6475V98.2915H9.05812C9.07174 98.6686 9.1766 98.963 9.37272 99.1748C9.56883 99.3866 9.84394 99.4924 10.198 99.4924C10.4731 99.4924 10.7333 99.462 10.9784 99.4011C11.2236 99.3401 11.4796 99.243 11.7465 99.1095V100.136C11.5286 100.252 11.2957 100.338 11.0479 100.393C10.8 100.451 10.4977 100.48 10.1408 100.48Z' fill='black'/>
        <path d='M16.2531 100.393L16.0856 99.7709H16.0202C15.8868 99.9972 15.6975 100.173 15.4523 100.297C15.2072 100.419 14.928 100.48 14.6147 100.48C14.0781 100.48 13.6737 100.328 13.4013 100.023C13.1289 99.7158 12.9927 99.2749 12.9927 98.7005V95.5284H14.2389V98.3698C14.2389 98.7208 14.2974 98.9848 14.4145 99.1617C14.5317 99.3358 14.7182 99.4228 14.9743 99.4228C15.3229 99.4228 15.5749 99.2995 15.7302 99.053C15.8854 98.8035 15.963 98.3916 15.963 97.8172V95.5284H17.2092V100.393H16.2531Z' fill='black'/>
        <path d='M20.4083 96.3813C20.1441 96.3813 19.9371 96.4712 19.7873 96.6511C19.6375 96.828 19.5517 97.0804 19.5299 97.4082H21.2786C21.2732 97.0804 21.1928 96.828 21.0375 96.6511C20.8823 96.4712 20.6726 96.3813 20.4083 96.3813ZM20.584 100.48C19.8486 100.48 19.2739 100.264 18.8598 99.8318C18.4458 99.3996 18.2388 98.7875 18.2388 97.9956C18.2388 97.1805 18.4295 96.551 18.8108 96.1071C19.1949 95.6604 19.7247 95.4371 20.4002 95.4371C21.0457 95.4371 21.5483 95.6329 21.9078 96.0245C22.2674 96.4161 22.4471 96.9571 22.4471 97.6475V98.2915H19.5013C19.5149 98.6686 19.6198 98.963 19.8159 99.1748C20.012 99.3866 20.2871 99.4924 20.6412 99.4924C20.9163 99.4924 21.1765 99.462 21.4216 99.4011C21.6668 99.3401 21.9228 99.243 22.1897 99.1095V100.136C21.9718 100.252 21.7389 100.338 21.4911 100.393C21.2432 100.451 20.9408 100.48 20.584 100.48Z' fill='black'/>
        <path d='M26.0099 95.4371C26.1788 95.4371 26.3191 95.4501 26.4307 95.4762L26.3368 96.7207C26.236 96.6917 26.1134 96.6772 25.9691 96.6772C25.5714 96.6772 25.2609 96.7859 25.0375 97.0035C24.8169 97.2211 24.7066 97.5257 24.7066 97.9173V100.393H23.4604V95.5284H24.4042L24.5881 96.3465H24.6494C24.791 96.0738 24.9817 95.8548 25.2214 95.6894C25.4638 95.5212 25.7266 95.4371 26.0099 95.4371Z' fill='black'/>
        <path d='M29.8015 95.4371C30.3408 95.4371 30.763 95.6619 31.0681 96.1115C31.3731 96.5582 31.5257 97.1718 31.5257 97.9521C31.5257 98.7556 31.3677 99.3779 31.0517 99.8188C30.7385 100.26 30.3108 100.48 29.7688 100.48C29.2322 100.48 28.8114 100.273 28.5063 99.8579H28.4205L28.2121 100.393H27.2601V93.6226H28.5063V95.1977C28.5063 95.3979 28.49 95.7184 28.4573 96.1594H28.5063C28.7978 95.6778 29.2295 95.4371 29.8015 95.4371ZM29.4011 96.4988C29.0933 96.4988 28.8686 96.6003 28.7269 96.8034C28.5853 97.0035 28.5118 97.3357 28.5063 97.7998V97.9434C28.5063 98.4655 28.5785 98.8398 28.7228 99.066C28.8699 99.2923 29.1015 99.4054 29.4174 99.4054C29.6735 99.4054 29.8764 99.2807 30.0262 99.0312C30.1787 98.7788 30.255 98.4133 30.255 97.9347C30.255 97.456 30.1787 97.0978 30.0262 96.8599C29.8737 96.6191 29.6653 96.4988 29.4011 96.4988Z' fill='black'/>
        <path d='M34.447 96.3813C34.1828 96.3813 33.9758 96.4712 33.826 96.6511C33.6761 96.828 33.5903 97.0804 33.5686 97.4082H35.3173C35.3118 97.0804 35.2315 96.828 35.0762 96.6511C34.9209 96.4712 34.7112 96.3813 34.447 96.3813ZM34.6227 100.48C33.8872 100.48 33.3125 100.264 32.8985 99.8318C32.4845 99.3996 32.2775 98.7875 32.2775 97.9956C32.2775 97.1805 32.4681 96.551 32.8495 96.1071C33.2335 95.6604 33.7633 95.4371 34.4388 95.4371C35.0844 95.4371 35.5869 95.6329 35.9465 96.0245C36.306 96.4161 36.4858 96.9571 36.4858 97.6475V98.2915H33.54C33.5536 98.6686 33.6584 98.963 33.8546 99.1748C34.0507 99.3866 34.3258 99.4924 34.6799 99.4924C34.955 99.4924 35.2151 99.462 35.4603 99.4011C35.7054 99.3401 35.9614 99.243 36.2284 99.1095V100.136C36.0105 100.252 35.7776 100.338 35.5297 100.393C35.2818 100.451 34.9795 100.48 34.6227 100.48Z' fill='black'/>
        <path d='M40.0486 95.4371C40.2174 95.4371 40.3577 95.4501 40.4694 95.4762L40.3754 96.7207C40.2746 96.6917 40.1521 96.6772 40.0077 96.6772C39.61 96.6772 39.2995 96.7859 39.0761 97.0035C38.8555 97.2211 38.7452 97.5257 38.7452 97.9173V100.393H37.499V95.5284H38.4429L38.6267 96.3465H38.688C38.8296 96.0738 39.0203 95.8548 39.26 95.6894C39.5024 95.5212 39.7653 95.4371 40.0486 95.4371Z' fill='black'/>
        <path d='M44.1997 100.393L43.9586 99.7318H43.9259C43.7162 100.013 43.4997 100.209 43.2763 100.319C43.0557 100.427 42.7669 100.48 42.4101 100.48C41.9716 100.48 41.6257 100.347 41.3723 100.08C41.1217 99.813 40.9964 99.433 40.9964 98.9398C40.9964 98.4235 41.1653 98.0435 41.5031 97.7998C41.8436 97.5532 42.3556 97.4169 43.0393 97.3908L43.832 97.3647V97.1515C43.832 96.6583 43.595 96.4117 43.121 96.4117C42.7561 96.4117 42.327 96.5292 41.834 96.7642L41.4214 95.8678C41.9471 95.5748 42.53 95.4284 43.1701 95.4284C43.7829 95.4284 44.2528 95.5705 44.5797 95.8548C44.9065 96.1391 45.07 96.5713 45.07 97.1515V100.393H44.1997ZM43.832 98.1392L43.3498 98.1566C42.9876 98.1682 42.7179 98.2378 42.5409 98.3655C42.3638 98.4931 42.2753 98.6875 42.2753 98.9485C42.2753 99.3227 42.4769 99.5098 42.88 99.5098C43.1687 99.5098 43.3989 99.4214 43.5705 99.2444C43.7448 99.0675 43.832 98.8325 43.832 98.5395V98.1392Z' fill='black'/>
        <path d='M48.2813 99.4228C48.4993 99.4228 48.7607 99.3721 49.0658 99.2705V100.258C48.7553 100.406 48.374 100.48 47.9218 100.48C47.4233 100.48 47.0597 100.347 46.8309 100.08C46.6048 99.8101 46.4918 99.4069 46.4918 98.8702V96.5249H45.8953V95.9636L46.5817 95.5197L46.9412 94.4928H47.7379V95.5284H49.0168V96.5249H47.7379V98.8702C47.7379 99.0588 47.787 99.198 47.885 99.2879C47.9858 99.3779 48.1179 99.4228 48.2813 99.4228Z' fill='black'/>
        <path d='M53.2292 100.393L53.0617 99.7709H52.9963C52.8628 99.9972 52.6735 100.173 52.4284 100.297C52.1832 100.419 51.9041 100.48 51.5908 100.48C51.0542 100.48 50.6497 100.328 50.3773 100.023C50.105 99.7158 49.9688 99.2749 49.9688 98.7005V95.5284H51.2149V98.3698C51.2149 98.7208 51.2735 98.9848 51.3906 99.1617C51.5077 99.3358 51.6943 99.4228 51.9504 99.4228C52.299 99.4228 52.551 99.2995 52.7062 99.053C52.8615 98.8035 52.9391 98.3916 52.9391 97.8172V95.5284H54.1853V100.393H53.2292Z' fill='black'/>
        <path d='M59.7092 100.393H58.4631V97.5518C58.4631 97.2008 58.4045 96.9382 58.2874 96.7642C58.1702 96.5872 57.9837 96.4988 57.7276 96.4988C57.379 96.4988 57.127 96.6235 56.9717 96.873C56.8165 97.1195 56.7389 97.53 56.7389 98.1044V100.393H55.4927V95.5284H56.4447L56.6122 96.1507H56.6817C56.8206 95.9157 57.0112 95.7387 57.2537 95.6198C57.4988 95.498 57.7766 95.4371 58.0872 95.4371C58.6183 95.4371 59.0214 95.5908 59.2965 95.8983C59.5716 96.2029 59.7092 96.6438 59.7092 97.2211V100.393Z' fill='black'/>
        <path d='M64.9676 95.5284V96.2029L64.2526 96.3987C64.3833 96.6162 64.4487 96.8599 64.4487 97.1297C64.4487 97.6519 64.2771 98.0594 63.9339 98.3524C63.5934 98.6425 63.1194 98.7875 62.512 98.7875L62.2873 98.7745L62.1035 98.7527C61.9754 98.8572 61.9114 98.9732 61.9114 99.1008C61.9114 99.2923 62.1402 99.388 62.5978 99.388H63.3741C63.8753 99.388 64.2566 99.5026 64.5181 99.7318C64.7823 99.9609 64.9145 100.297 64.9145 100.741C64.9145 101.31 64.6911 101.751 64.2444 102.064C63.8004 102.377 63.1617 102.534 62.3282 102.534C61.6908 102.534 61.2032 102.415 60.8655 102.177C60.5304 101.942 60.3629 101.612 60.3629 101.185C60.3629 100.892 60.4487 100.647 60.6203 100.45C60.7919 100.252 61.0439 100.112 61.3762 100.028C61.2482 99.9696 61.1365 99.8754 61.0412 99.7448C60.9458 99.6114 60.8982 99.4707 60.8982 99.3227C60.8982 99.1371 60.9486 98.9833 61.0493 98.8615C61.1501 98.7368 61.2958 98.6149 61.4865 98.496C61.2468 98.3858 61.0561 98.2088 60.9145 97.9651C60.7756 97.7215 60.7061 97.4343 60.7061 97.1036C60.7061 96.5727 60.8682 96.1623 61.1923 95.8722C61.5165 95.5821 61.9795 95.4371 62.5815 95.4371C62.7095 95.4371 62.8607 95.4501 63.035 95.4762C63.2121 95.4994 63.3251 95.5168 63.3741 95.5284H64.9676ZM61.4416 101.081C61.4416 101.263 61.5233 101.407 61.6867 101.511C61.8529 101.616 62.0844 101.668 62.3813 101.668C62.828 101.668 63.178 101.603 63.4313 101.472C63.6846 101.342 63.8113 101.163 63.8113 100.937C63.8113 100.754 63.7364 100.628 63.5866 100.559C63.4368 100.489 63.2052 100.454 62.892 100.454H62.2465C62.0177 100.454 61.8256 100.511 61.6704 100.624C61.5178 100.74 61.4416 100.892 61.4416 101.081ZM61.8951 97.121C61.8951 97.385 61.9509 97.5938 62.0626 97.7476C62.177 97.9013 62.35 97.9782 62.5815 97.9782C62.8157 97.9782 62.9873 97.9013 63.0963 97.7476C63.2052 97.5938 63.2597 97.385 63.2597 97.121C63.2597 96.535 63.0336 96.242 62.5815 96.242C62.1239 96.242 61.8951 96.535 61.8951 97.121Z' fill='black'/>
        <path d='M68.9103 98.9485C68.9103 99.4475 68.7469 99.8275 68.42 100.089C68.0959 100.35 67.6097 100.48 66.9614 100.48C66.6291 100.48 66.3458 100.456 66.1116 100.406C65.8773 100.36 65.6581 100.29 65.4538 100.197V99.1008C65.6853 99.2169 65.9454 99.314 66.2341 99.3924C66.5256 99.4707 66.7816 99.5098 67.0023 99.5098C67.4544 99.5098 67.6805 99.3706 67.6805 99.0921C67.6805 98.9877 67.6506 98.9036 67.5906 98.8398C67.5307 98.773 67.4272 98.6991 67.2801 98.6178C67.133 98.5337 66.9369 98.4365 66.6918 98.3263C66.3404 98.1697 66.0816 98.0246 65.9155 97.8912C65.752 97.7577 65.6322 97.6054 65.5559 97.4343C65.4824 97.2602 65.4456 97.047 65.4456 96.7947C65.4456 96.3624 65.6022 96.0288 65.9155 95.7939C66.2314 95.556 66.6781 95.4371 67.2556 95.4371C67.8058 95.4371 68.341 95.5647 68.8613 95.82L68.4854 96.7772C68.2566 96.6728 68.0428 96.5872 67.8439 96.5205C67.6451 96.4538 67.4422 96.4204 67.2352 96.4204C66.8674 96.4204 66.6836 96.5263 66.6836 96.7381C66.6836 96.857 66.7421 96.96 66.8593 97.047C66.9791 97.1341 67.2392 97.2631 67.6396 97.4343C67.9965 97.588 68.258 97.7316 68.4241 97.8651C68.5903 97.9985 68.7128 98.1522 68.7918 98.3263C68.8708 98.5004 68.9103 98.7078 68.9103 98.9485Z' fill='black'/>
        <path d='M73.8582 95.5284V96.2029L73.1432 96.3987C73.2739 96.6162 73.3393 96.8599 73.3393 97.1297C73.3393 97.6519 73.1677 98.0594 72.8245 98.3524C72.484 98.6425 72.01 98.7875 71.4026 98.7875L71.1779 98.7745L70.994 98.7527C70.866 98.8572 70.802 98.9732 70.802 99.1008C70.802 99.2923 71.0308 99.388 71.4884 99.388H72.2647C72.7659 99.388 73.1472 99.5026 73.4087 99.7318C73.673 99.9609 73.8051 100.297 73.8051 100.741C73.8051 101.31 73.5817 101.751 73.135 102.064C72.691 102.377 72.0523 102.534 71.2188 102.534C70.5814 102.534 70.0938 102.415 69.7561 102.177C69.421 101.942 69.2535 101.612 69.2535 101.185C69.2535 100.892 69.3393 100.647 69.5109 100.45C69.6825 100.252 69.9345 100.112 70.2668 100.028C70.1388 99.9696 70.0271 99.8754 69.9318 99.7448C69.8364 99.6114 69.7888 99.4707 69.7888 99.3227C69.7888 99.1371 69.8391 98.9833 69.9399 98.8615C70.0407 98.7368 70.1864 98.6149 70.3771 98.496C70.1374 98.3858 69.9467 98.2088 69.8051 97.9651C69.6662 97.7215 69.5967 97.4343 69.5967 97.1036C69.5967 96.5727 69.7588 96.1623 70.0829 95.8722C70.4071 95.5821 70.8701 95.4371 71.4721 95.4371C71.6001 95.4371 71.7513 95.4501 71.9256 95.4762C72.1027 95.4994 72.2157 95.5168 72.2647 95.5284H73.8582ZM70.3322 101.081C70.3322 101.263 70.4139 101.407 70.5773 101.511C70.7435 101.616 70.975 101.668 71.2719 101.668C71.7186 101.668 72.0686 101.603 72.3219 101.472C72.5752 101.342 72.7019 101.163 72.7019 100.937C72.7019 100.754 72.627 100.628 72.4772 100.559C72.3274 100.489 72.0958 100.454 71.7826 100.454H71.1371C70.9083 100.454 70.7162 100.511 70.561 100.624C70.4084 100.74 70.3322 100.892 70.3322 101.081ZM70.7857 97.121C70.7857 97.385 70.8415 97.5938 70.9532 97.7476C71.0676 97.9013 71.2406 97.9782 71.4721 97.9782C71.7063 97.9782 71.8779 97.9013 71.9869 97.7476C72.0958 97.5938 72.1503 97.385 72.1503 97.121C72.1503 96.535 71.9242 96.242 71.4721 96.242C71.0145 96.242 70.7857 96.535 70.7857 97.121Z' fill='black'/>
        <path d='M76.5057 96.3813C76.2415 96.3813 76.0345 96.4712 75.8847 96.6511C75.7349 96.828 75.6491 97.0804 75.6273 97.4082H77.376C77.3706 97.0804 77.2902 96.828 77.1349 96.6511C76.9797 96.4712 76.7699 96.3813 76.5057 96.3813ZM76.6814 100.48C75.946 100.48 75.3713 100.264 74.9572 99.8318C74.5432 99.3996 74.3362 98.7875 74.3362 97.9956C74.3362 97.1805 74.5269 96.551 74.9082 96.1071C75.2923 95.6604 75.8221 95.4371 76.4976 95.4371C77.1431 95.4371 77.6457 95.6329 78.0052 96.0245C78.3648 96.4161 78.5445 96.9571 78.5445 97.6475V98.2915H75.5987C75.6123 98.6686 75.7172 98.963 75.9133 99.1748C76.1094 99.3866 76.3845 99.4924 76.7386 99.4924C77.0137 99.4924 77.2739 99.462 77.519 99.4011C77.7642 99.3401 78.0202 99.243 78.2871 99.1095V100.136C78.0692 100.252 77.8363 100.338 77.5885 100.393C77.3406 100.451 77.0382 100.48 76.6814 100.48Z' fill='black'/>
        <path d='M82.7447 98.9485C82.7447 99.4475 82.5813 99.8275 82.2544 100.089C81.9303 100.35 81.4441 100.48 80.7958 100.48C80.4635 100.48 80.1802 100.456 79.9459 100.406C79.7117 100.36 79.4924 100.29 79.2881 100.197V99.1008C79.5197 99.2169 79.7798 99.314 80.0685 99.3924C80.36 99.4707 80.616 99.5098 80.8366 99.5098C81.2888 99.5098 81.5149 99.3706 81.5149 99.0921C81.5149 98.9877 81.4849 98.9036 81.425 98.8398C81.3651 98.773 81.2616 98.6991 81.1145 98.6178C80.9674 98.5337 80.7713 98.4365 80.5261 98.3263C80.1747 98.1697 79.916 98.0246 79.7498 97.8912C79.5864 97.7577 79.4666 97.6054 79.3903 97.4343C79.3167 97.2602 79.28 97.047 79.28 96.7947C79.28 96.3624 79.4366 96.0288 79.7498 95.7939C80.0658 95.556 80.5125 95.4371 81.09 95.4371C81.6402 95.4371 82.1754 95.5647 82.6957 95.82L82.3198 96.7772C82.091 96.6728 81.8771 96.5872 81.6783 96.5205C81.4795 96.4538 81.2765 96.4204 81.0695 96.4204C80.7018 96.4204 80.5179 96.5263 80.5179 96.7381C80.5179 96.857 80.5765 96.96 80.6936 97.047C80.8135 97.1341 81.0736 97.2631 81.474 97.4343C81.8308 97.588 82.0923 97.7316 82.2585 97.8651C82.4246 97.9985 82.5472 98.1522 82.6262 98.3263C82.7052 98.5004 82.7447 98.7078 82.7447 98.9485Z' fill='black'/>
        <path d='M85.6088 96.3813C85.3446 96.3813 85.1376 96.4712 84.9878 96.6511C84.838 96.828 84.7522 97.0804 84.7304 97.4082H86.4791C86.4736 97.0804 86.3933 96.828 86.238 96.6511C86.0827 96.4712 85.873 96.3813 85.6088 96.3813ZM85.7845 100.48C85.049 100.48 84.4743 100.264 84.0603 99.8318C83.6463 99.3996 83.4393 98.7875 83.4393 97.9956C83.4393 97.1805 83.6299 96.551 84.0113 96.1071C84.3953 95.6604 84.9251 95.4371 85.6006 95.4371C86.2462 95.4371 86.7487 95.6329 87.1083 96.0245C87.4678 96.4161 87.6476 96.9571 87.6476 97.6475V98.2915H84.7018C84.7154 98.6686 84.8202 98.963 85.0164 99.1748C85.2125 99.3866 85.4876 99.4924 85.8417 99.4924C86.1168 99.4924 86.3769 99.462 86.6221 99.4011C86.8672 99.3401 87.1232 99.243 87.3902 99.1095V100.136C87.1723 100.252 86.9394 100.338 86.6915 100.393C86.4436 100.451 86.1413 100.48 85.7845 100.48Z' fill='black'/>
        <path d='M89.907 100.393H88.6609V93.6226H89.907V100.393Z' fill='black'/>
        <path d='M92.4647 100.393H91.2185V93.6226H92.4647V100.393Z' fill='black'/>
        <path d='M96.9631 98.9485C96.9631 99.4475 96.7997 99.8275 96.4728 100.089C96.1487 100.35 95.6625 100.48 95.0142 100.48C94.6819 100.48 94.3986 100.456 94.1644 100.406C93.9301 100.36 93.7108 100.29 93.5066 100.197V99.1008C93.7381 99.2169 93.9982 99.314 94.2869 99.3924C94.5784 99.4707 94.8344 99.5098 95.0551 99.5098C95.5072 99.5098 95.7333 99.3706 95.7333 99.0921C95.7333 98.9877 95.7033 98.9036 95.6434 98.8398C95.5835 98.773 95.48 98.6991 95.3329 98.6178C95.1858 98.5337 94.9897 98.4365 94.7445 98.3263C94.3932 98.1697 94.1344 98.0246 93.9683 97.8912C93.8048 97.7577 93.685 97.6054 93.6087 97.4343C93.5352 97.2602 93.4984 97.047 93.4984 96.7947C93.4984 96.3624 93.655 96.0288 93.9683 95.7939C94.2842 95.556 94.7309 95.4371 95.3084 95.4371C95.8586 95.4371 96.3938 95.5647 96.9141 95.82L96.5382 96.7772C96.3094 96.6728 96.0956 96.5872 95.8967 96.5205C95.6979 96.4538 95.495 96.4204 95.2879 96.4204C94.9202 96.4204 94.7364 96.5263 94.7364 96.7381C94.7364 96.857 94.7949 96.96 94.9121 97.047C95.0319 97.1341 95.292 97.2631 95.6924 97.4343C96.0493 97.588 96.3107 97.7316 96.4769 97.8651C96.6431 97.9985 96.7656 98.1522 96.8446 98.3263C96.9236 98.5004 96.9631 98.7078 96.9631 98.9485Z' fill='black'/>
        <path d='M99.7905 100.48C98.3686 100.48 97.6577 99.6491 97.6577 97.9869C97.6577 97.1602 97.8511 96.5292 98.2379 96.0941C98.6246 95.6561 99.1789 95.4371 99.9008 95.4371C100.429 95.4371 100.903 95.5473 101.323 95.7678L100.955 96.7947C100.759 96.7105 100.576 96.6424 100.407 96.5901C100.239 96.535 100.07 96.5075 99.9008 96.5075C99.2525 96.5075 98.9284 96.9977 98.9284 97.9782C98.9284 98.9297 99.2525 99.4054 99.9008 99.4054C100.14 99.4054 100.362 99.3721 100.567 99.3053C100.771 99.2357 100.975 99.1284 101.18 98.9833V100.119C100.978 100.255 100.774 100.35 100.567 100.402C100.362 100.454 100.104 100.48 99.7905 100.48Z' fill='black'/>
        <path d='M106.458 100.393H105.212V97.5518C105.212 96.8498 104.967 96.4988 104.477 96.4988C104.128 96.4988 103.876 96.625 103.721 96.8773C103.566 97.1297 103.488 97.5387 103.488 98.1044V100.393H102.242V93.6226H103.488V95.0019C103.488 95.1093 103.479 95.3616 103.459 95.759L103.431 96.1507H103.496C103.774 95.6749 104.215 95.4371 104.82 95.4371C105.357 95.4371 105.764 95.5908 106.042 95.8983C106.319 96.2058 106.458 96.6467 106.458 97.2211V100.393Z' fill='black'/>
        <path d='M110.642 100.393L110.401 99.7318H110.368C110.159 100.013 109.942 100.209 109.719 100.319C109.498 100.427 109.209 100.48 108.853 100.48C108.414 100.48 108.068 100.347 107.815 100.08C107.564 99.813 107.439 99.433 107.439 98.9398C107.439 98.4235 107.608 98.0435 107.946 97.7998C108.286 97.5532 108.798 97.4169 109.482 97.3908L110.274 97.3647V97.1515C110.274 96.6583 110.038 96.4117 109.564 96.4117C109.199 96.4117 108.77 96.5292 108.277 96.7642L107.864 95.8678C108.39 95.5748 108.973 95.4284 109.613 95.4284C110.225 95.4284 110.695 95.5705 111.022 95.8548C111.349 96.1391 111.512 96.5713 111.512 97.1515V100.393H110.642ZM110.274 98.1392L109.792 98.1566C109.43 98.1682 109.16 98.2378 108.983 98.3655C108.806 98.4931 108.718 98.6875 108.718 98.9485C108.718 99.3227 108.919 99.5098 109.323 99.5098C109.611 99.5098 109.841 99.4214 110.013 99.2444C110.187 99.0675 110.274 98.8325 110.274 98.5395V98.1392Z' fill='black'/>
        <path d='M115.324 96.5249H114.246V100.393H113V96.5249H112.313V95.8852L113 95.5284V95.1716C113 94.6176 113.128 94.2129 113.384 93.9576C113.64 93.7023 114.05 93.5747 114.614 93.5747C115.044 93.5747 115.427 93.6429 115.762 93.7792L115.443 94.7539C115.192 94.6698 114.961 94.6277 114.748 94.6277C114.571 94.6277 114.443 94.6843 114.364 94.7974C114.285 94.9076 114.246 95.0498 114.246 95.2238V95.5284H115.324V96.5249Z' fill='black'/>
        <path d='M117.968 99.4228C118.186 99.4228 118.447 99.3721 118.752 99.2705V100.258C118.442 100.406 118.061 100.48 117.608 100.48C117.11 100.48 116.746 100.347 116.518 100.08C116.291 99.8101 116.178 99.4069 116.178 98.8702V96.5249H115.582V95.9636L116.268 95.5197L116.628 94.4928H117.425V95.5284H118.703V96.5249H117.425V98.8702C117.425 99.0588 117.474 99.198 117.572 99.2879C117.672 99.3779 117.805 99.4228 117.968 99.4228Z' fill='black'/>
        <path d='M125.943 100.393H124.697V97.5518C124.697 97.2008 124.641 96.9382 124.53 96.7642C124.421 96.5872 124.248 96.4988 124.011 96.4988C123.692 96.4988 123.461 96.6235 123.316 96.873C123.172 97.1224 123.1 97.5329 123.1 98.1044V100.393H121.854V95.5284H122.805L122.973 96.1507H123.042C123.165 95.9273 123.342 95.7532 123.574 95.6285C123.805 95.5009 124.071 95.4371 124.37 95.4371C125.054 95.4371 125.517 95.6749 125.759 96.1507H125.87C125.992 95.9244 126.172 95.7489 126.409 95.6242C126.649 95.4994 126.918 95.4371 127.218 95.4371C127.736 95.4371 128.127 95.5792 128.391 95.8635C128.658 96.1449 128.791 96.5974 128.791 97.2211V100.393H127.541V97.5518C127.541 97.2008 127.485 96.9382 127.373 96.7642C127.264 96.5872 127.091 96.4988 126.854 96.4988C126.549 96.4988 126.321 96.6148 126.168 96.8469C126.018 97.0789 125.943 97.4473 125.943 97.9521V100.393Z' fill='black'/>
        <path d='M132.615 95.4371C133.155 95.4371 133.577 95.6619 133.882 96.1115C134.187 96.5582 134.34 97.1718 134.34 97.9521C134.34 98.7556 134.182 99.3779 133.866 99.8188C133.552 100.26 133.125 100.48 132.583 100.48C132.046 100.48 131.625 100.273 131.32 99.8579H131.234L131.026 100.393H130.074V93.6226H131.32V95.1977C131.32 95.3979 131.304 95.7184 131.271 96.1594H131.32C131.612 95.6778 132.043 95.4371 132.615 95.4371ZM132.215 96.4988C131.907 96.4988 131.682 96.6003 131.541 96.8034C131.399 97.0035 131.326 97.3357 131.32 97.7998V97.9434C131.32 98.4655 131.392 98.8398 131.537 99.066C131.684 99.2923 131.915 99.4054 132.231 99.4054C132.487 99.4054 132.69 99.2807 132.84 99.0312C132.993 98.7788 133.069 98.4133 133.069 97.9347C133.069 97.456 132.993 97.0978 132.84 96.8599C132.688 96.6191 132.479 96.4988 132.215 96.4988Z' fill='black'/>
        <path d='M140.362 100.393H139.099V97.6475H136.734V100.393H135.467V94.0316H136.734V96.5249H139.099V94.0316H140.362V100.393Z' fill='black'/>
      </svg>
    </Link>
  )
}

export default IconLogo
