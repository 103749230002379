import { createMuiTheme, ThemeOptions } from '@material-ui/core/styles'
import { red } from '@material-ui/core/colors'

// Create a theme instance.
const themeBase = createMuiTheme({
  palette: {
    primary: {
      main: '#556cd6',
      light: '#EAEBF2',
      dark: '#CCCED7',
    },
    secondary: {
      main: '#19857b',
      light: '#FFC700',
    },
    error: {
      main: red.A400,
    },
    background: {
      default: '#fff',
    },
  },
})

export const MD_DOWN = 961
export const SD_DOWN = 601
export const XS_DOWN = 320

const theme = createMuiTheme({

  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },

  shadows: [
    `none`,
    `none`,
    `none`,
    `none`,
    `none`,
    `none`,
    `none`,
    `none`,
    `none`,
    `none`,
    `none`,
    `none`,
    `none`,
    `none`,
    `none`,
    `none`,
    `none`,
    `none`,
    `none`,
    `none`,
    `none`,
    `none`,
    `none`,
    `0px 16px 48px rgba(255, 184, 0, 0.4)`,
    `0px 4px 60px rgba(0, 0, 0, 0.08)`,
  ],

  typography: {
    fontFamily: 'Open Sans, sans-serif',
  },


  // Custom
  overrides: {
    MuiButton: {
      contained: {
        textTransform: 'none',
        fontWeight: 700,
        backgroundColor: '#fff',
        boxShadow: '0px 4px 24px rgba(0, 0, 0, 0.04)',
        borderRadius: '40px',
      },
      containedPrimary: {
        textTransform: 'none',
        // boxShadow: `0px 16px 48px rgba(255, 184, 0, 0.4)`,
        borderRadius: '40px',
      },
    },

    MuiCircularProgress: {
      colorSecondary: {
        color: '#5398F8',
      },
    },
    MuiContainer: {
      root: {
        paddingLeft: '24px',
        paddingRight: '24px',
      },
    },
  },
  ...themeBase,
} as ThemeOptions)

theme.typography.h1 = {
  fontWeight: 700,
  fontSize: '72px',
  lineHeight: '86px',
  marginBottom: '32px',
  [theme.breakpoints.down('lg')]: {
    // fontSize: '52px',
    // lineHeight: '62px',
  },
  [theme.breakpoints.down(960)]: {
    fontSize: '52px',
    lineHeight: '62px',
    marginBottom: '16px',
  },
  [theme.breakpoints.down(600)]: {
    fontSize: '44px',
    lineHeight: '52px',
  },
  [theme.breakpoints.down(320)]: {
    fontSize: '24px',
    lineHeight: '33px',
    marginBottom: '8px',
  },
}

theme.typography.h2 = {
  fontWeight: 700,
  fontSize: '52px',
  lineHeight: '62px',
  marginBottom: '32px',
  [theme.breakpoints.down('lg')]: {
    // fontSize: '52px',
    // lineHeight: '62px',
  },
  [theme.breakpoints.down(961)]: {
    fontSize: '44px',
    lineHeight: '52px',
    marginBottom: '32px',
  },
  [theme.breakpoints.down(601)]: {
    fontSize: '32px',
    lineHeight: '38px',
    marginBottom: '8px',
  },
  [theme.breakpoints.down(321)]: {
    fontSize: '20px',
    lineHeight: '28px',
    marginBottom: '8px',
  },
}


theme.typography.h3 = {
  fontWeight: 700,
  fontSize: '52px',
  lineHeight: '62px',
  [theme.breakpoints.down('lg')]: {
    // fontSize: '52px',
    // lineHeight: '62px',
  },
  [theme.breakpoints.down(961)]: {
    fontSize: '44px',
    lineHeight: '52px',
  },
  [theme.breakpoints.down(601)]: {
    fontSize: '24px',
    lineHeight: '34px',
  },
  [theme.breakpoints.down(321)]: {
    fontSize: '20px',
    lineHeight: '28px',
  },
}

theme.typography.subtitle1 = {
  fontWeight: 700,
  fontSize: '32px',
  lineHeight: '38px',
  marginBottom: '16px',
  [theme.breakpoints.down(961)]: {
    fontSize: '32px',
    lineHeight: '38px',
    marginBottom: '16px',
  },
  [theme.breakpoints.down(601)]: {
    fontSize: '24px',
    lineHeight: '33px',
    marginBottom: '8px',
  },
  [theme.breakpoints.down(321)]: {
    fontSize: '20px',
    lineHeight: '28px',
    marginBottom: '8px',
  },
}

theme.typography.body1 = {
  fontWeight: 400,
  fontSize: '20px',
  lineHeight: '32px',
  [theme.breakpoints.down(961)]: {
    fontSize: '16px',
    lineHeight: '25px',
  },
  [theme.breakpoints.down(321)]: {
    fontSize: '14px',
    lineHeight: '22px',
  },
}

// theme.typography.body2 = {
//   fontWeight: 400,
//   fontSize: '14px',
//   lineHeight: '16px',
// }

export const contentSpacing = {
  marginTop: '60px',
  [theme.breakpoints.down(961)]: {
    marginTop: '32px',
  },
  [theme.breakpoints.down(321)]: {
    marginTop: '16px',
  },
}

export default theme